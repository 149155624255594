<template>
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 200.000000 200.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
      fill="#6bf0e3"
      stroke="none"
    >
      <path
        d="M820 1976 c-381 -80 -666 -340 -777 -711 -25 -83 -27 -104 -27 -265
        0 -162 2 -182 27 -265 116 -383 419 -654 801 -716 117 -18 311 -7 421 25 432
        125 725 512 725 956 0 445 -299 836 -730 956 -117 32 -334 42 -440 20z m280
        -526 c13 -7 20 -26 25 -69 6 -55 10 -61 47 -86 l40 -27 60 26 c47 21 64 24 74
        15 23 -19 104 -169 98 -182 -3 -7 -27 -29 -53 -49 -46 -35 -48 -39 -46 -84 2
        -39 7 -51 31 -68 16 -12 40 -31 53 -42 l25 -21 -51 -89 c-27 -49 -58 -91 -67
        -92 -10 -2 -41 6 -69 18 l-51 22 -42 -23 c-40 -21 -43 -26 -49 -77 -11 -86 -4
        -82 -125 -82 l-108 0 -7 43 c-13 89 -15 92 -58 116 l-42 23 -48 -21 c-27 -12
        -56 -21 -66 -21 -18 0 -111 149 -111 178 0 10 23 35 50 55 47 35 50 40 50 81
        0 41 -4 47 -50 86 -27 23 -50 48 -50 56 0 16 86 171 100 179 4 2 36 -7 70 -21
        l62 -25 39 25 c38 26 43 35 53 110 3 22 11 43 18 48 18 11 176 10 198 -2z"
      />
      <path
        d="M929 1137 c-103 -55 -118 -179 -30 -261 25 -24 42 -30 91 -34 52 -4
        66 -1 99 21 70 47 94 150 48 218 -25 38 -97 79 -138 79 -14 0 -46 -10 -70 -23z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SettingsIcon",
};
</script>
