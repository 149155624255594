<template>
  <the-header></the-header>
  <div class="wrapper">
    <div class="container">
      <bread-crumb></bread-crumb>
      <router-view/>
    </div>
  </div>
  <the-footer></the-footer>
</template>

<script>
import BreadCrumb from "@/components/UI/BreadCrumb";
import TheHeader from "@/components/layouts/Aministration/TheHeader";
import TheFooter from "@/components/layouts/Aministration/TheFooter";

export default {
  components: {TheHeader, TheFooter, BreadCrumb},
  async created() {
    await this.$store.dispatch('setCountersAction')
  }
}
</script>

<style scoped>
.wrapper {
  padding: 10rem 0 3rem 0;
}

@media (max-width: 980px) {
  .wrapper {
    padding-top: 5rem;
  }
}
</style>