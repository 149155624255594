<template>
  <div class="page-title-box">
    <h5>{{ currentText }}</h5>
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-if="parentRoute && parentText">
        <router-link :to="parentRoute"
          ><strong>{{ parentText }}</strong></router-link
        >
      </li>
      <li v-if="currentRoute && currentText && parentRoute && parentText">
        &nbsp;<i class="fa fa-chevron-left fa-1x"></i>&nbsp;
      </li>
      <li class="breadcrumb-item" v-if="currentRoute && currentText">
        <router-link :to="currentRoute"
          ><strong>{{ currentText }}</strong></router-link
        >
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
    dashboards() {
      return ["admin.dashboard", "user.dashboard"];
    },
    currentText() {
      return !!this.current ? this.current.meta.title : "";
    },
    currentRoute() {
      return !!this.current ? this.current.path : "";
    },
    parentText() {
      if (!this.parent)
        return !this.dashboards.includes(this.current.name)
          ? "لوحة التحكم"
          : "";
      return this.parent.meta.title;
    },
    parentRoute() {
      if (!this.parent)
        return !this.dashboards.includes(this.current.name)
          ? {
              name: this.$store.getters.isAdmin
                ? "admin.dashboard"
                : "user.dashboard",
            }
          : "";
      return this.parent.path;
    },
    current() {
      return this.$route;
    },
    parent() {
      return this.current.matched.find(
        (r) => r.path === this.$router.options.history.state.back
      );
    },
  },
};
</script>

<style scoped>
.page-title-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.breadcrumb {
  border-radius: 0;
  padding: 0.5rem;
  display: flex;
  box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.15);
}

@media (max-width: 640px) {
  .page-title-box {
    display: none;
  }
}
</style>
